var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-btn',{staticClass:"mb-4",on:{"click":function($event){return _vm.edit(null)}}},[_vm._v(" Neu "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fas fa-plus")])],1),_c('v-expansion-panels',{attrs:{"tile":"","mandatory":"","focusable":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[(_vm.editItem != null)?_c('v-expansion-panel',{attrs:{"disabled":_vm.editLoading}},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.editItem.ID == null ? 'Erstellen' : ("[" + (_vm.editItem.ID) + "] '" + (_vm.editItem.gameshort) + " " + (_vm.editItem.name) + "' bearbeiten"))+" ")]),_c('v-expansion-panel-content',[_c('v-form',{model:{value:(_vm.editValid),callback:function ($$v) {_vm.editValid=$$v},expression:"editValid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.rule_notempty,"label":"Name","required":""},model:{value:(_vm.editItem.name),callback:function ($$v) {_vm.$set(_vm.editItem, "name", $$v)},expression:"editItem.name"}}),_c('v-select',{attrs:{"rules":_vm.rule_notempty,"items":_vm.games,"item-value":"ID","item-text":"name","label":"Game","required":""},model:{value:(_vm.editItem.game),callback:function ($$v) {_vm.$set(_vm.editItem, "game", $$v)},expression:"editItem.game"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.rule_notempty,"label":"Gegründet","prepend-icon":"mdi-calendar","readonly":"","required":""},model:{value:(_vm.editItem.founded),callback:function ($$v) {_vm.$set(_vm.editItem, "founded", $$v)},expression:"editItem.founded"}},'v-text-field',attrs,false),on))]}}],null,false,1584586823)},[_c('v-date-picker',{model:{value:(_vm.editItem.founded),callback:function ($$v) {_vm.$set(_vm.editItem, "founded", $$v)},expression:"editItem.founded"}})],1),_c('v-text-field',{attrs:{"label":"About"},model:{value:(_vm.editItem.about),callback:function ($$v) {_vm.$set(_vm.editItem, "about", $$v)},expression:"editItem.about"}})],1)],1),_c('v-row',[_c('v-autocomplete',{attrs:{"items":_vm.users,"label":"Member","item-text":"displayname","item-value":"ID","filter":_vm.memberFilter,"return-object":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._g(_vm._b({},'v-chip',data.attrs,false),data.on),[_c('v-avatar',{attrs:{"left":""}},[_c('axz-img',{attrs:{"id":data.item.image,"dummy":"trikot_aXz.png"}})],1),_vm._v(" "+_vm._s(data.item.displayname)+" ")],1)]}},{key:"item",fn:function(data){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',data.attrs,false),data.on),[_c('v-list-item-avatar',[_c('axz-img',{attrs:{"id":data.item.image,"dummy":"trikot_aXz.png"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.displayname))])],1)],1)]}}],null,false,2709427784),model:{value:(_vm.editItem.members),callback:function ($$v) {_vm.$set(_vm.editItem, "members", $$v)},expression:"editItem.members"}})],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.cols_member,"items":_vm.editItem.members,"item-key":"ID","items-per-page":-1,"dense":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":_vm.rule_notempty,"label":"Role","single-line":"","dense":"","required":""},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}})]}},{key:"item.sort",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.editItem.members.findIndex(function (i) { return i.ID == item.ID; }) > 0),expression:"editItem.members.findIndex(i => i.ID == item.ID) > 0"}],staticClass:"px-2",attrs:{"small":""},on:{"click":function($event){return _vm.sortMoveUp(item)}}},[_vm._v("fas fa-long-arrow-alt-up")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.editItem.members.findIndex(function (i) { return i.ID == item.ID; }) < _vm.editItem.members.length-1),expression:"editItem.members.findIndex(i => i.ID == item.ID) < editItem.members.length-1"}],staticClass:"px-2",attrs:{"small":""},on:{"click":function($event){return _vm.sortMoveDown(item)}}},[_vm._v("fas fa-long-arrow-alt-down")])]}}],null,false,1171615571)})],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"end"}},[(_vm.editItem.ID == null)?_c('v-btn',{attrs:{"loading":_vm.editLoading,"disabled":!_vm.editValid},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Anlegen "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fas fa-save")])],1):_c('v-btn',{attrs:{"loading":_vm.editLoading,"disabled":!_vm.editValid},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Speichern "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fas fa-save")])],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Teams ")]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.cols,"items":_vm.teams,"item-key":"ID","items-per-page":-1,"dense":"","hide-default-footer":true,"show-expand":"","single-expand":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("fas fa-pen")])]}},{key:"group.header",fn:function(ref){
var headers = ref.headers;
var group = ref.group;
return [_c('td',{staticClass:"text-caption text-uppercase pl-2",attrs:{"colspan":headers.length}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-"+_vm._s(group == '1' ? 'globe' : 'lock'))]),_vm._v(_vm._s(group == '1' ? 'Veröffentlicht' : 'Unveröffentlicht'))],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"About","readonly":""},model:{value:(item.about),callback:function ($$v) {_vm.$set(item, "about", $$v)},expression:"item.about"}})],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }