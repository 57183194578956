<template>
    <v-container>
        <v-btn class="mb-4" @click="edit(null)">
            Neu <v-icon right small>fas fa-plus</v-icon>
        </v-btn>
        <v-expansion-panels v-model="selected" tile mandatory focusable>
            <v-expansion-panel v-if="editItem != null" :disabled="editLoading">
                <v-expansion-panel-header>
                    {{editItem.ID == null ? 'Erstellen' : `[${editItem.ID}] '${editItem.gameshort} ${editItem.name}' bearbeiten`}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form v-model="editValid">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="editItem.name" :rules="rule_notempty" label="Name" required></v-text-field>
                                    <v-select v-model="editItem.game" :rules="rule_notempty" :items="games" item-value="ID" item-text="name" label="Game" required></v-select>
                                    <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="editItem.founded" :rules="rule_notempty" v-bind="attrs" v-on="on" label="Gegründet" prepend-icon="mdi-calendar" readonly required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editItem.founded"></v-date-picker>
                                    </v-menu>
                                    <v-text-field v-model="editItem.about" label="About"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-autocomplete v-model="editItem.members" :items="users" label="Member" item-text="displayname" item-value="ID" :filter="memberFilter" return-object multiple>
                                    <template v-slot:selection="data">
                                        <v-chip v-bind="data.attrs" v-on="data.on">
                                            <v-avatar left>
                                                <axz-img :id="data.item.image" dummy="trikot_aXz.png" />
                                            </v-avatar>
                                            {{data.item.displayname}}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="data" >
                                        <v-list-item v-bind="data.attrs" v-on="data.on">
                                            <v-list-item-avatar>
                                                <axz-img :id="data.item.image" dummy="trikot_aXz.png" />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ data.item.displayname }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                    <!--template v-slot:selection="data">
                                        <v-chip v-bind="data.attrs" :close="data.item.email != $store.userData.email" @click:close="remove(create.members, data.item, 'email');remove(create.admins, data.item, 'email');" :disabled="data.item.email == $store.userData.email">
                                            <v-avatar left>
                                                
                                                <v-img :src="'https://zimks2141clone.uni-trier.de:8081/img/avatars/avatar.php?id='+data.item.id"></v-img>
                                            </v-avatar>
                                            {{data.item.email}}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="data" >
                                        <v-list-item v-show="data.item.email != $store.userData.email" v-bind="data.attrs" v-on="data.on">
                                            <v-list-item-avatar>
                                                
                                                <img :src="'https://zimks2141clone.uni-trier.de:8081/img/avatars/avatar.php?id='+data.item.id">
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ data.item.firstname }} {{ data.item.lastname }}</v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template-->
                                </v-autocomplete>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-data-table :headers="cols_member" :items="editItem.members" item-key="ID" :items-per-page="-1" dense :hide-default-footer="true">
                                        <template v-slot:item.role="{ item }">
                                            <v-text-field v-model="item.role" :rules="rule_notempty" label="Role" single-line dense required></v-text-field>
                                        </template>
                                        <template v-slot:item.sort="{ item }">
                                            <v-icon v-show="editItem.members.findIndex(i => i.ID == item.ID) > 0" class="px-2" small @click="sortMoveUp(item)">fas fa-long-arrow-alt-up</v-icon>
                                            <v-icon v-show="editItem.members.findIndex(i => i.ID == item.ID) < editItem.members.length-1" class="px-2" small @click="sortMoveDown(item)">fas fa-long-arrow-alt-down</v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <v-row justify="end" class="mt-4">
                                <v-btn v-if="editItem.ID == null" @click="create()" :loading="editLoading" :disabled="!editValid">
                                    Anlegen <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                                <v-btn v-else @click="update()" :loading="editLoading" :disabled="!editValid">
                                    Speichern <v-icon right small>fas fa-save</v-icon>
                                </v-btn>
                            </v-row>
                            
                        </v-container>
                    </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Teams
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-data-table :headers="cols" :items="teams" item-key="ID" :items-per-page="-1" dense :hide-default-footer="true" show-expand single-expand :loading="loading">
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="ml-2" @click="edit(item)">fas fa-pen</v-icon>
                        </template>
                        <template v-slot:group.header="{ headers, group }">
                            <td :colspan="headers.length" class="text-caption text-uppercase pl-2"><v-icon small class="mr-2">fas fa-{{group == '1' ? 'globe' : 'lock'}}</v-icon>{{group  == '1' ? 'Veröffentlicht' : 'Unveröffentlicht'}}</td>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-text-field v-model="item.about" label="About" readonly></v-text-field>
                                    </v-col>
                                </v-row>
                            </td>
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            cols: [
                //{ text: 'ID', value: 'ID' },
                { text: 'Name', value: 'name' },
                { text: 'Game', value: 'gameshort' },
                { text: 'Gegründet', value: 'founded' },
                { text: 'Rosteränderung', value: 'rosterupdate' },
                { text: 'Aktionen', value: 'actions', align: 'end', sortable: false },
                { text: '', value: 'data-table-expand' },
            ],
            cols_member: [
                //{ text: 'ID', value: 'ID' },
                { text: 'Name', value: 'displayname' },
                { text: 'Role', value: 'role' },
                { text: 'Sortierung', value: 'sort', align: 'end' },
            ],
            teams: [],
            games: [],
            users: [],
            selected: 0,
            editItem: null,
            editValid: false,
            editLoading: false,
            image_rules: [
                value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
            ],
            rule_notempty: [
                v => !!v || 'required',
            ],
        }
    },
    computed: {
        image_preview () {
            if(this.editItem.image_upload != null)
                return URL.createObjectURL(this.editItem.image_upload);
            else if(this.editItem.image != null)
                return this.editItem.image;
            else return null;
        }
    },
    watch: {
        selected () {
            if(this.selected != 1 && this.editItem != null){
                this.editItem = null;
                this.loadTeams(); //or clone edit item !=??!?!?
            }
        },
    },
    methods: {
        memberFilter(item, queryText) { 
            return item.displayname.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
        },
        sortMoveUp(item) {
            var i = this.editItem.members.findIndex(i => i.ID == item.ID);
            console.log(`Found at ${i}`);
            if(i > 0){
                this.editItem.members.splice(i-1, 2, item, this.editItem.members[i-1]);
                item.sort = i-1;
            }
        },
        sortMoveDown(item) {
            var i = this.editItem.members.findIndex(i => i.ID == item.ID);
            console.log(`Found at ${i}`);
            if(i < this.editItem.members.length-1){
                this.editItem.members.splice(i, 2, this.editItem.members[i+1], item);
                item.sort = i+1;
            }
        },
        load(_class, _function, key, callback) {
            return this.call(_class, _function).then(res => {
                console.log(res);
                this[key] = [];
                if(res.success && res.data != null) {
                    if(callback != null) {
                        res.data.forEach(item => {
                            callback(item);
                            this[key].push(item);
                        });
                    }
                    else this[key] = res.data;
                }
            });
        },
        loadGames(){
            this.loading = true;
            this.load('games', 'get', 'games').then(() => {
                this.loading = false;
            });
        },
        loadUsers(){
            this.load('users', 'getAll', 'users', item => {
                item.invited = new Date(item.invited).toLocaleString('de-DE');
                item.registered = new Date(item.registered).toLocaleString('de-DE');
            });
        },
        loadTeams(){
            this.loading = true;
            this.load('teams', 'getAll', 'teams', /*item => {
                //item.founded = new Date(item.founded).toLocaleString('de-DE');
                //item.rosterupdate = new Date(item.rosterupdate).toLocaleString('de-DE');
            }*/).then(() => {
                this.loading = false;
            });
        },
        edit(item) {
            if(item != null) {
                this.editItem = Object.assign(item);
            }
            else {
                this.editItem = {
                    ID: null,
                    name: null,
                    game: '1',
                    founded: null,
                    about: '',
                    members: [],
                }
            }
            this.selected = 1;
        },
        create(){
            this.editLoading = true;
            this.call('teams', 'create', {
                name: this.editItem.name,
                game: this.editItem.game,
                founded: this.editItem.founded,
                about: this.editItem.about,
            }).then((res) => {
                if(res.success){
                    this.editItem.ID = res.data;
                    this.updateMembership().then(() => {
                        this.editLoading = false;
                        this.editItem = null;
                        this.loadTeams();
                    });
                }
                else {
                    this.editLoading = false;
                    this.editItem = null;
                    this.loadTeams();
                }
            });
            
        },
        update(){
            this.editLoading = true;
            this.call('teams', 'update', {
                ID: this.editItem.ID,
                name: this.editItem.name,
                game: this.editItem.game,
                founded: this.editItem.founded,
                about: this.editItem.about,
            }).then((res) => {
                if(res.success){
                    this.updateMembership().then(() => {
                        this.editLoading = false;
                        this.editItem = null;
                        this.loadTeams();
                    });
                }
                else {
                    this.editLoading = false;
                    this.editItem = null;
                    this.loadTeams();
                }
            });
        },
        updateMembership(){
            var members = [];
            this.editItem.members.forEach((item, i) => {
                members.push({ ID: item.ID, role: item.role, sort: i });
            });
            //console.log(members);
            return this.call('teams', 'updateMembership', { ID: this.editItem.ID, members: JSON.stringify(members) });
        }
    },
    mounted() {
        this.loadGames();
        this.loadUsers();
        this.loadTeams();
    }
}
</script>